// @flow

// IMPORTS
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import colors from 'styles/colors';
import { getMapVehicleStatus, getMapCustomIcons } from 'helpers';
import { Popup, Marker } from 'mapbox-gl';
import { getTranslationList } from '../../i18n';
import { format } from 'date-fns';
import clockIcon from 'assets/clock.svg';
import calendarIcon from 'assets/calendar-icon.svg';
import spreaderIcon from 'assets/spreader_green.svg';
import saltWaterIcon from 'assets/salt_water.svg';
import stateIcon from 'assets/state.svg';
import blockIcon from 'assets/block.png';
import routeIcon from 'assets/route.svg';
import modeIcon from 'assets/mode.svg';
import speedIcon from 'assets/speedIcon.svg';
import NavigationArrow from 'assets/statuses/navigation-arrow-rotate-square.svg';
import mapPin from 'assets/location-pin-2965.svg';
import mapPinStart from 'assets/location-pin-start.svg';
import mapPinEnd from 'assets/location-pin-end.svg';
import moment from 'moment';
import equipment from 'assets/vehicle-green.svg';
import { getAuxStatus, setSpreaderState, setSpreaderMode, getMaterial } from 'helpers';
const dateFormat = 'YYYY-MM-DD HH:mm';
// RESOURCES
import { mapboxGeoToAddress } from 'services/Vehicle/resources';
import {isImperial} from "../../services/Units/thunks";

/**
 * For future reference:
 * Mapbox API: https://docs.mapbox.com/mapbox-gl-js/api
 * Mapbox style specification: https://docs.mapbox.com/mapbox-gl-js/style-spec
 */

// Easier way to add all images under 'app/assets' into an array.
// This will be used to load images into mapbox at init
/**
 *
 */
const popup = new Popup({
    anchor: 'bottom',
    offset: 24,
    closeButton: false,
    closeOnClick: false,
    maxWidth: 120,
    zIndex: -1,
});

const permanentPopup = new Popup({
    anchor: 'bottom',
    offset: 50,
    closeButton: true,
    maxWidth: 220,
});

let marker = new Marker({
    color: colors.yellow,
});

// boolean to detect if markers have been initialized
let isMarkerInitialize = false;

let PDRhoveredStateId = null;
let HistoryhoveredStateId = null;
let HistoryPDRhoveredStateId = null;
let PDRMAtchinghoveredStateId = null;
const iconElementStart = document.createElement('div');
iconElementStart.innerHTML = `<img src="${mapPinStart}" style="width: 40px; height: 40px; position: relative; bottom: 20px; left: 7px;" alt="Marker Icon">`;

const historyStartMarker = new Marker({
    element: iconElementStart,
});

const iconElementEnd = document.createElement('div');
iconElementEnd.innerHTML = `<img src="${mapPinEnd}" style="width: 40px; height: 40px; position: relative; bottom: 20px; left: 7px;" alt="Marker Icon">`;

const historyEndMarker = new Marker({
    element: iconElementEnd,
});

/**
 *
 */
const imagesContext = require.context('assets/statuses', true, /.svg$/);
const imagesStatus = require.context('assets/status', true, /.svg$/);
const imagesCustomVehicle = require.context('assets/custom/vehicleIcons', true, /.svg$/);

/**
 *
 */
let allImages = imagesContext.keys().map((key) => imagesContext(key));
let allImagesStatus = imagesStatus.keys().map((key) => imagesStatus(key));
let allImagesCustom = imagesCustomVehicle.keys().map((key) => imagesCustomVehicle(key));
allImages.push(...allImagesStatus);
/**
 *
 */
const clusterImagesContext = require.context('assets/clusters', true, /.svg$/);

/**
 *
 */
const allClusters = clusterImagesContext.keys().map((key) => clusterImagesContext(key));

/**
 * Mapbox requires an svg to be rasterized as an HTML Image object for `addImage()`
 * @param {*} img
 * @param {*} from
 * @param {*} size
 * @param {*} index
 */
const imageCache = new Map();
const fetchPromises = new Map();

const loadImage = (img, from, size, index) => {
    return new Promise((resolve, reject) => {
        let allImageInitLength = allImages.length - allImagesStatus.length;

        if (img === '/87f82fd1b18df8da95e32886bf753ba9.svg') {
            size = 35;
        }
        if (index >= allImageInitLength) {
            size = 35;
        }

        if (imageCache.has(img)) {
            resolve(imageCache.get(img));
            return;
        }

        if (fetchPromises.has(img)) {
            fetchPromises
                .get(img)
                .then(resolve)
                .catch(reject);
            return;
        }

        const image = new Image(size, size);
        const fetchPromise = new Promise((res, rej) => {
            image.onload = () => {
                imageCache.set(img, image);
                fetchPromises.delete(img);
                res(image);
            };
            image.onerror = () => {
                fetchPromises.delete(img);
                rej(imagesContext.keys()[from.indexOf(img)]);
            };

            image.src = img;
        });

        fetchPromises.set(img, fetchPromise);
        fetchPromise.then(resolve).catch(reject);
    });
};

const loadImageCustom = (img, from, size = 80) => {
    return new Promise((resolve, reject) => {
        if (imageCache.has(img)) {
            resolve(imageCache.get(img));
            return;
        }

        if (fetchPromises.has(img)) {
            fetchPromises
                .get(img)
                .then(resolve)
                .catch(reject);
            return;
        }

        const imageVehIcon = new Image(size, size);
        const fetchPromise = new Promise((res, rej) => {
            imageVehIcon.onload = () => {
                imageCache.set(img, imageVehIcon);
                fetchPromises.delete(img);
                res(imageVehIcon);
            };
            imageVehIcon.onerror = () => {
                fetchPromises.delete(img);
                rej(imagesCustomVehicle.keys()[from.indexOf(img)]);
            };

            imageVehIcon.src = img;
        });

        fetchPromises.set(img, fetchPromise);
        fetchPromise.then(resolve).catch(reject);
    });
};

export const observeImages = (images) => {
    const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const imgElement = entry.target;
                    const imgSrc = imgElement.getAttribute('data-src');
                    const imgIndex = imgElement.getAttribute('data-index');
                    const imgSize = imgElement.getAttribute('data-size');

                    loadImage(imgSrc, null, imgSize, imgIndex)
                        .then((loadedImg) => {
                            imgElement.src = loadedImg.src;
                        })
                        .catch((error) => {
                            console.error('Error loading image:', error);
                        });

                    observer.unobserve(imgElement);
                }
            });
        },
        {
            threshold: 0.1,
            rootMargin: '0px 0px 200px 0px',
        }
    );

    images.forEach((img) => {
        observer.observe(img);
    });
};
// Avoiding duplication of this case logic. Means the same as
/**
 * const compareKey = isCluster ? 'cluster_id' : 'sortKey'
 * if(circle[compareKey] === id){
 *  return caseTrue;
 * } else {
 *  return caseFalse;
 * }
 * @template T
 * @param {number} id id to compare to
 * @param {T} caseTrue return in case of id comparing to false
 * @param {T} caseFalse return in case of id comparing to false
 * @param {boolean} isCluster should compare to cluster_id or not
 * @returns {Array<any>} Compare expression
 */
const idEquals = (id, caseTrue, caseFalse, isCluster) => [
    'case',
    ['==', ['get', isCluster ? 'cluster_id' : 'sortKey'], id],
    caseTrue,
    caseFalse,
];

/**
 *
 * @param {*} map
 */
const loadAllClusterImages = (map) =>
    Promise.all(allClusters.map((img) => loadImage(img, allClusters, 100))).then((imgs) => {
        imgs.forEach((img, index) => {
            if (img && !map.hasImage(allClusters[index])) map.addImage(allClusters[index], img);
        });
    });

/**
 * Generates clusters images for map
 * @param {*} length
 * @param {*} map
 * @param {*} param2
 */
const makeClusterSwitch = (length, map, [clusterId, pointCount] = [-1, -1]) => {
    const numbers = Array.from({ length: length + 1 }, (_, i) => i);
    const fallbackImage = clusterImagesContext('./map-cluster000.svg');
    const base = [
        'step',
        ['get', 'point_count'],
        fallbackImage,
        ...numbers.reduce((prev, num) => {
            num = `00${num}`;
            const image = `./map-cluster${num.slice(-3)}.svg`;
            const highlightedImage = `./map-cluster${num.slice(-3)}-highlight.svg`;
            if (clusterImagesContext.keys().indexOf(image) > -1) {
                const importedImage = clusterImagesContext(image);
                prev.push(Number(num));
                if (clusterId !== -1 && pointCount !== -1 && pointCount === Number(num)) {
                    prev.push(
                        idEquals(
                            clusterId,
                            clusterImagesContext(highlightedImage),
                            importedImage,
                            true
                        )
                    );
                } else {
                    prev.push(importedImage);
                }
            }
            return prev;
        }, []),
    ];
    return base;
};

// Aux vars for accumulating multiple routes data
/**
 *
 */
const pingData = {
    type: 'FeatureCollection',
    features: [],
};

/**
 *
 */
const eventData = {
    type: 'FeatureCollection',
    features: [],
};

/**
 *
 */
const auxData = {
    type: 'FeatureCollection',
    features: [],
};

/**
 *
 */
let vehicleData = {
    type: 'FeatureCollection',
    features: [],
};

/**
 *
 */
const aux1Data = {
    type: 'FeatureCollection',
    features: [],
};

/**
 *
 */
const aux2Data = {
    type: 'FeatureCollection',
    features: [],
};

/**
 *
 */
const aux3Data = {
    type: 'FeatureCollection',
    features: [],
};

/**
 *
 */
const aux4Data = {
    type: 'FeatureCollection',
    features: [],
};

/**
 *
 */
const aux1OffData = {
    type: 'FeatureCollection',
    features: [],
};

/**
 *
 */
const aux2OffData = {
    type: 'FeatureCollection',
    features: [],
};

/**
 *
 */
const aux3OffData = {
    type: 'FeatureCollection',
    features: [],
};

/**
 *
 */
const aux4OffData = {
    type: 'FeatureCollection',
    features: [],
};
/**
 *
 */
let lineData = {
    type: 'FeatureCollection',
    features: [],
};

/**
 *
 */
let routeData = {
    type: 'FeatureCollection',
    features: [],
};

/**
 *
 */
let routePDRHistoryData = {
    type: 'FeatureCollection',
    features: [],
};

/**
 *
 */
let routePDRmatchingData = {
    type: 'FeatureCollection',
    features: [],
};

/**
 *
 */
let routePDRData = {
    type: 'FeatureCollection',
    features: [],
};

/**
 *
 */
let predfinedRouteData = {
    type: 'FeatureCollection',
    features: [],
};

/**
 *
 */
let geoJsonCircle = {
    type: 'FeatureCollection',
    features: [],
};

/**
 *
 */
let circleGeoPoint = {
    type: 'FeatureCollection',
    features: [],
};

/**
 *
 */
let geoJsonPolygone = {
    type: 'FeatureCollection',
    features: [],
};

/**
 *
 */
let startedImageLoad = false;

/**
 * Since loading is async, this will get called on multiple renders
 * so we use a global to set that it has started
 * @param {*} map
 * @param {*} forceLoadImages
 */

let vehDatas = null;
const set = new Set(allImages);
const setCustom = new Set(allImagesCustom);
const filtered = Array.from(set);
const filteredCustom = Array.from(setCustom);

let firstInit = true;
const loadedImages = new Set(); // Keep track of loaded images

async function convertSvgToDataUrlWithColors(vehicleId, svgUrl, primaryColor, secondaryColor) {
    //
    try {
        const response = await fetch(svgUrl);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        let svgText = await response.text();

        // Vérifiez quelles classes sont présentes dans le SVG
        const hasSt0 = svgText.includes('class="st0"');
        const hasSt1 = svgText.includes('class="st1"');
        const hasSt2 = svgText.includes('class="st2"');

        if (hasSt1 && hasSt2 && hasSt0) {
            svgText = svgText.replace(/class="st1"/g, `style="fill:${primaryColor};" class="st1"`);
            svgText = svgText.replace(
                /class="st2"/g,
                `style="fill:${secondaryColor};" class="st2"`
            );
            svgText = svgText.replace(/<g class="st0">.*?<\/g>/s, '');
        } else if (hasSt0 && hasSt1) {
            svgText = svgText.replace(/class="st0"/g, `style="fill:${primaryColor};"`);
            svgText = svgText.replace(/class="st1"/g, `style="fill:${secondaryColor};"`);
        }

        const encodedSvg = encodeURIComponent(svgText);
        const dataUrl = `data:image/svg+xml,${encodedSvg}`;

        return dataUrl;
    } catch (error) {
        console.error(`Erreur lors de la conversion de l'icône SVG : ${error}`);
        throw error; // Relancer l'erreur pour gérer cela en amont
    }
}

export const initLayers = async (map, forceLoadImages, branchId, fleetEvents) => {
    if (!map) return;
    const isclusterByBranchId = branchId == 1235 || branchId == 155
    if (forceLoadImages || !startedImageLoad) {
        startedImageLoad = true;
        if (vehDatas && vehDatas.length > 0) {
            const customList = vehDatas.filter((veh) => {
                return (
                    veh.properties.iconCustom.icons &&
                    veh.properties.iconCustom.icons.vehicle &&
                    veh.properties.iconCustom.icons.vehicle.color !== undefined &&
                    veh.properties.status.description !== 'KEY_OFF'
                );
            });

            const iconPromises = customList.map(async (veh) => {
                const vehicleIcon = veh.properties.iconCustom.icons.vehicle.img;
                const primaryColor = veh.properties.iconCustom.icons.vehicle.color.primary;
                const secondaryColor = veh.properties.iconCustom.icons.vehicle.color.secondary;

                try {
                    const dataUrl = await convertSvgToDataUrlWithColors(
                        veh.id,
                        vehicleIcon,
                        primaryColor,
                        secondaryColor
                    );
                    const imageId = `${veh.id}`;
                    const image = new Image(35, 35);
                    image.src = dataUrl;

                    await new Promise((resolve, reject) => {
                        if (image) {
                            image.onload = () => {
                                if (!map.hasImage(imageId)) {
                                    map.addImage(imageId, image);
                                }
                                resolve(); // Résoudre la promesse ici
                            };

                            image.onerror = (error) => {
                                console.error(
                                    `Erreur lors du chargement de l'image pour ${imageId}:`,
                                    error
                                );
                                reject(error); // Rejeter la promesse ici
                            };
                        }
                    });
                    return Promise.resolve();
                } catch (error) {
                    console.error(
                        `Erreur lors du chargement de l'icône pour le véhicule ${veh.id}:`,
                        error
                    );
                    return Promise.resolve(); // Retourne une promesse résolue pour éviter de bloquer l'exécution
                }
            });

            // Attendre que toutes les promesses d'icônes soient résolues
            await Promise.all(iconPromises);
        }

        if (map.getLayer('icons')) {
            try {
                const standardImgs = await Promise.all(
                    filtered.map((img, index) => loadImage(img, allImages, 65, index))
                );
                const standardImagePromises = [];

                standardImgs.forEach((img, index) => {
                    const imageId = filtered[index];
                    if (!loadedImages.has(imageId) && img) {
                        standardImagePromises.push({ id: imageId, img });
                    }
                });

                // Ajoutez les images standards
                standardImagePromises.forEach(({ id, img }) => {
                    if (!map.hasImage(id)) {
                        map.addImage(id, img);
                    }
                });
            } catch (e) {
                console.error('ERROR - initLayers - filtered images:', e);
            }
        }

        firstInit = false;
        if (map.getSource('vehicles') && !map.getLayer('icons')) {
            map.addLayer({
                id: 'icons',
                type: 'symbol',
                source: 'vehicles',
                layout: {
                    'icon-allow-overlap': true,
                    'text-field': [
                        'case',
                        ['boolean', ['get', 'showVehicleName'], false],
                        ['get', 'name'],
                        '',
                    ],
                    'text-anchor': 'bottom',
                    'text-offset': [0, -1.5],
                    'text-justify': 'center',
                    'text-size': 14,
                    'text-font': ['Arial Unicode MS Bold', 'Open Sans Bold'],
                },
                paint: {
                    'text-color': '#007acc',
                    'text-halo-color': '#e0f7ff',
                    'text-halo-width': 2,
                },
            });
        }
        if (map.getSource('vehicles')) {
            vehDatas = map.getSource('vehicles')._data.features;
        }
    }
    const imagesToObserve = document.querySelectorAll('img[data-src]');
    observeImages(imagesToObserve);
    if (!map.getSource('vehicles')) {
        map.addSource('vehicles', {
            type: 'geojson',
            data: vehicleData,
            cluster: isclusterByBranchId,
            clusterMinPoints: 5,
            clusterRadius: 40, // If other points fall within this circle, they form a cluster. Making the circle smaller or larger changes how often clusters form.
            clusterMaxZoom: 12, // how much you can zoom in before clusters break apart into individual points. A higher value means you have to zoom in more to see all points separately
        });

        map.addLayer({
            id: 'vehicles-icons-highlight',
            type: 'symbol',
            source: 'vehicles',
            // Only display icons on points that aren't vehicle clusters
            filter: ['!has', 'point_count'],
            layout: {
                visibility: 'visible',
                'icon-allow-overlap': true,
                'icon-image': ['get', 'icon'],
                'icon-size': 0,
                'symbol-sort-key': ['get', 'sortKey'],
            },
        });

        map.addLayer({
            id: 'routific-shadow',
            type: 'circle',
            source: 'vehicles',
            layout: {
                visibility: 'visible',
            },
            paint: {
                'circle-radius': ['get', 'circleRadius'],
                'circle-color': ['get', 'shadowColor'],
                'circle-blur': ['get', 'circleBlur'],
            },
        });

        map.addLayer({
            id: 'vehicles-clusters-icon',
            type: 'circle',
            source: 'vehicles',
            filter: ['has', 'point_count'],
            paint: {
                // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
                'circle-color': [
                    'step',
                    ['get', 'point_count'],
                    '#62ab36',
                    25,
                    '#62ab36',
                    100,
                    '#62ab36',
                ],
                'circle-radius': ['step', ['get', 'point_count'], 20, 25, 20, 100, 20],
            },
        });

        map.addLayer({
            id: 'vehicles-clusters',
            type: 'symbol',
            source: 'vehicles',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 16,
                'icon-allow-overlap': true,
            },
            paint: {
                'text-color': '#fff',
            },
        });

        map.addLayer({
            id: 'vehicles-icons',
            type: 'symbol',
            source: 'vehicles',
            // Only display icons on points that aren't vehicle clusters
            filter: ['!has', 'point_count'],
            layout: {
                visibility: 'visible',
                'icon-allow-overlap': true,
                'icon-image': ['get', 'icon'],
                'icon-size': 0.5,
                // 'icon-rotate': ['coalesce', ['get', 'heading'], 0],
                'symbol-sort-key': ['get', 'sortKey'],
            },
            paint: {
                'text-color': '#202',
                'text-halo-color': '#fff',
                'text-halo-width': 2,
            },
        });

        map.addLayer({
            id: 'vehicles-icons-arrow',
            type: 'symbol',
            source: 'vehicles',
            // Only display icons on points that aren't vehicle clusters
            filter: [
                'all',
                ['!has', 'point_count'],
                ['has', 'heading'],
                ['>', 'heading', 0],
                ['<', 'heading', 360],
                ['==', 'isKey', true],
            ],
            layout: {
                visibility: 'visible',
                'icon-allow-overlap': true,
                'icon-image': NavigationArrow,
                'icon-size': 0.5,
                'icon-rotate': ['get', 'heading'],
                'symbol-sort-key': ['get', 'sortKey'],
                'text-anchor': 'bottom',
                'text-offset': [0, -2],
                'text-justify': 'center',
                'text-allow-overlap': true,
            },
            paint: {
                'text-color': '#202',
                'text-halo-color': '#fff',
                'text-halo-width': 2,
            },
        });

        map.addLayer({
            id: 'icons',
            type: 'symbol',
            source: 'vehicles',
            layout: {
                'icon-allow-overlap': true,
                'text-field': [
                    'case',
                    ['boolean', ['get', 'showVehicleName'], false],
                    ['get', 'name'],
                    '',
                ],
                'text-anchor': 'bottom',
                'text-offset': [0, -1.5], // the space between the vehicle name and the icon
                'text-justify': 'center',
                'text-size': 14,
                'text-font': ['Arial Unicode MS Bold', 'Open Sans Bold'],
            },
            paint: {
                'text-color': '#007acc',
                'text-halo-color': '#e0f7ff',
                'text-halo-width': 2,
            },
        });
    }
    // Line data and visual layers
    if (!map.getSource('line')) {
        map.addSource('line', {
            type: 'geojson',
            data: lineData,
        });
        // Line comes first in the stack so that arrows will be on top
        map.addLayer({
            id: 'line',
            type: 'line',
            source: 'line',
            layout: {
                visibility: 'visible',
            },
            paint: {
                'line-color': `#1E90FF`,
                'line-width': 3,
                'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 6, 3],
            },
        });
        map.addLayer({
            id: 'arrows',
            type: 'symbol',
            source: 'line',
            layout: {
                visibility: 'visible',
                'symbol-placement': 'line',
                'text-field': '▶',
                'text-size': 22,
                'symbol-spacing': 70,
                'text-keep-upright': false,
            },
            paint: {
                'text-color': `${colors.routeArrow}`,
                'text-halo-color': `${colors.routeArrowHalo}`,
                'text-halo-width': 3,
            },
        });
    }

    if (map.getSource('line') && fleetEvents && fleetEvents.length > 0) {

            // Create GeoJSON features array
            const features = fleetEvents
                .filter((event) => event.eventTypeId === 7)
                .map((event) => {
                    const { latitude, longitude, datetime, speed } = event;
                    const calculatedSpeed = isImperial() ? speed * 0.621371 : speed;
                    const speedUnit = isImperial() ? 'mph' : 'km/h';
                    const formattedSpeed = `${calculatedSpeed.toFixed(0)} ${speedUnit}`;
                    return {
                        type: 'Feature',
                        properties: {
                            datetime,
                            speed: formattedSpeed,
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [longitude, latitude],
                        },
                    };
                });

            // Create a GeoJSON feature collection
            const geoJSON = {
                type: 'FeatureCollection',
                features,
            };

            // Remove the existing event layer and source if they exist
            if (map.getLayer('events-layer')) {
                map.removeLayer('events-layer');
            }
            if (map.getSource('events-source')) {
                map.removeSource('events-source');
            }

            // Add the new source with all events
            map.addSource('events-source', {
                type: 'geojson',
                data: geoJSON,
            });

            // Add a single layer for all event points
            map.addLayer({
                id: 'events-layer',
                type: 'circle',
                source: 'events-source',
                minzoom: 10,
                paint: {
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        10,
                        2, // At zoom level 10, the radius is 2 pixels
                        16,
                        6, // At zoom level 16, the radius is 6 pixels
                    ],
                    'circle-color': '#62AB36',
                    'circle-stroke-color': 'black',
                    'circle-stroke-width': 1,
                },
            });
       // }
    }
    // Line data and visual layers
    if (!map.getSource('predefinedRouteLine')) {
        map.addSource('predefinedRouteLine', {
            type: 'geojson',
            data: predfinedRouteData,
            generateId: true,
        });

        // Line comes first in the stack so that arrows will be on top
        map.addLayer({
            id: 'predefinedRouteLine',
            type: 'line',
            source: 'predefinedRouteLine',
            layout: {
                visibility: 'visible',
            },
            paint: {
                'line-color': ['get', 'color'],
                'line-opacity': 0.6,
                'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 6, 3],
            },
        });

        map.addLayer({
            id: 'predefinedRouteLinearrows',
            type: 'symbol',
            source: 'predefinedRouteLine',
            layout: {
                visibility: 'visible',
                'symbol-placement': 'line',
                'text-field': '▶',
                'text-size': 22,
                'symbol-spacing': 70,
                'text-keep-upright': false,
            },
            paint: {
                'text-color': ['get', 'color'],
                'text-halo-color': `${colors.routeArrowHalo}`,
                'text-halo-width': 3,
            },
        });
    }

    if (!map.getSource('predefinedrouteResults')) {
        map.addSource('predefinedrouteResults', {
            type: 'geojson',
            data: routePDRData,
            generateId: true,
        });
        map.addLayer({
            id: 'predefinedrouteResults',
            type: 'line',
            source: 'predefinedrouteResults',
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
                visibility: 'visible',
            },
            paint: {
                'line-color': ['get', 'color'],
                'line-opacity': 0.6,
                'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 8, 5],
            },
        });
        map.addLayer({
            id: 'predefinedrouteResultsarrows',
            type: 'symbol',
            source: 'predefinedrouteResults',
            layout: {
                visibility: 'visible',
                'symbol-placement': 'line',
                'text-field': '▶',
                'text-size': 22,
                'symbol-spacing': 70,
                'text-keep-upright': false,
            },
            paint: {
                'text-color': ['get', 'color'],
                'text-halo-color': `${colors.routeArrowHalo}`,
                'text-halo-width': 5,
            },
        });
    }

    if (!map.getSource('predefinedroutematching')) {
        map.addSource('predefinedroutematching', {
            type: 'geojson',
            data: routePDRmatchingData,
            generateId: true,
        });
        map.addLayer({
            id: 'predefinedroutematching',
            type: 'line',
            source: 'predefinedroutematching',
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
                visibility: 'visible',
            },
            paint: {
                'line-color': ['get', 'color'],
                'line-opacity': 0.6,
                'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 7, 4],
            },
        });
        map.addLayer({
            id: 'predefinedroutematchingarrows',
            type: 'symbol',
            source: 'predefinedroutematching',
            layout: {
                visibility: 'visible',
                'symbol-placement': 'line',
                'text-field': '▶',
                'text-size': 22,
                'symbol-spacing': 70,
                'text-keep-upright': false,
            },
            paint: {
                'text-color': ['get', 'color'],
                'text-halo-color': `${colors.routeArrowHalo}`,
                'text-halo-width': 4,
            },
        });
    }

    if (!map.getSource('predefinedroutehistory')) {
        map.addSource('predefinedroutehistory', {
            type: 'geojson',
            data: routePDRHistoryData,
        });
        map.addLayer({
            id: 'predefinedroutehistory',
            type: 'line',
            source: 'predefinedroutehistory',
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
                visibility: 'visible',
            },
            paint: {
                'line-color': ['get', 'color'],
                'line-opacity': 0.6,
                'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 6, 3],
            },
        });
        map.addLayer({
            id: 'predefinedroutehistoryarrows',
            type: 'symbol',
            source: 'predefinedroutehistory',
            layout: {
                visibility: 'visible',
                'symbol-placement': 'line',
                'text-field': '▶',
                'text-size': 22,
                'symbol-spacing': 70,
                'text-keep-upright': false,
            },
            paint: {
                'text-color': ['get', 'color'],
                'text-halo-color': `${colors.routeArrowHalo}`,
                'text-halo-width': 3,
            },
        });
    }

    if (!map.getSource('route')) {
        map.addSource('route', {
            type: 'geojson',
            data: routeData,
        });
        map.addLayer({
            id: 'route',
            type: 'line',
            source: 'route',
            layout: {
                'line-join': 'round',
                'line-cap': 'round',
                visibility: 'none',
            },
            paint: {
                'line-color': `#1E90FF`,
                'line-width': ['case', ['boolean', ['feature-state', 'hover'], false], 6, 3],
            },
            // 'paint': {
            //     'line-color': '#888',
            //     'line-width': 8,
            // }
        });
    }

    // Pings/events data and visual layers
    // if (!map.getSource('pings')) {
    //     map.addSource('pings', {
    //         type: 'geojson',
    //         data: pingData,
    //     });
    //     map.addLayer({
    //         id: 'pings',
    //         type: 'circle',
    //         source: 'pings',
    //         layout: {
    //             visibility: 'visible',
    //         },
    //         paint: {
    //             /**
    //              * Equivalent to
    //              * if(has('point_count')) {
    //              *  return 10
    //              * }else {
    //              *  return 6;
    //              * }
    //              */
    //             'circle-radius': 5,
    //             // Same for color
    //             'circle-color': ['case', ['has', 'point_count'], colors.green63, ['get', 'color']],
    //         },
    //     });
    // }
    // Events data and visual circle layer
    if (!map.getSource('events')) {
        map.addSource('events', {
            type: 'geojson',
            data: eventData,
        });
    }

    if (!map.getLayer('events')) {
        map.addLayer({
            id: 'events',
            type: 'symbol',
            source: 'events',
            filter: ['!in', 'eventTypeId', 9, 17, 18, 19],
            layout: {
                visibility: 'visible',
                'icon-allow-overlap': true,
                'icon-image': ['get', 'icon'],
                'icon-size': 0.5,
                'symbol-sort-key': ['get', 'sortKey'],
            },
        });
    }
    if (!map.getLayer('auxEvents')) {
        map.addLayer({
            id: 'auxEvents',
            type: 'circle',
            source: 'events',
            filter: ['in', 'eventTypeId', 9, 17, 18, 19],
            paint: {
                'circle-radius': 6 * 1.5,
                'circle-color': ['get', 'dotColor'],
            },
        });
    }
};

/**
 *
 * @param {*} map
 */
export const resetGeofences = (map) => {
    if (!map) return;
    if (map.getSource('circles')) {
        map.getSource('circles').setData({
            type: 'FeatureCollection',
            features: [],
        });
    }
    if (map.getSource('polygons')) {
        map.getSource('polygons').setData({
            type: 'FeatureCollection',
            features: [],
        });
    }
    if (map.getSource('geofencesCirlces')) {
        map.getSource('geofencesCirlces').setData({
            type: 'FeatureCollection',
            features: [],
        });
    }
    geoJsonCircle.features = [];
    geoJsonPolygone.features = [];
    circleGeoPoint.features = [];
};

/**
 *
 * @param {*} map
 */
export const resetPredefinedRoutes = (map) => {
    if (!map) return;
    if (map.getSource('predefinedRouteLine')) {
        map.getSource('predefinedRouteLine').setData({
            type: 'FeatureCollection',
            features: [],
        });
    }
    predfinedRouteData.features = [];
};

/**
 *
 * @param {*} data
 * @param {*} map
 */
export const setLineData = (data, map) => {
    if (!map) return;
    lineData = data;
    if (map.getSource('line')) {
        map.getSource('line').setData(lineData);
    }
};

/**
 *
 * @param {*} data
 * @param {*} map
 */
export const setRoutePDRmatchingData = (data, map) => {
    if (!map) return;
    routePDRmatchingData = data;
    if (map.getSource('predefinedroutematching')) {
        map.getSource('predefinedroutematching').setData(routePDRmatchingData);
    }
};

/**
 *
 * @param {*} data
 * @param {*} map
 */
export const setRoutePDRData = (data, map) => {
    if (!map) return;
    routePDRData = data;
    if (map.getSource('predefinedrouteResults')) {
        map.getSource('predefinedrouteResults').setData(routePDRData);
    }
};

/**
 *
 * @param {*} data
 * @param {*} map
 */
export const setRoutePDRHistoryData = (data, map) => {
    if (!map) return;
    routePDRHistoryData = data;
    if (map.getSource('predefinedroutehistory')) {
        map.getSource('predefinedroutehistory').setData(routePDRHistoryData);
    }
};

/**
 *
 * @param {*} data
 * @param {*} map
 */
export const setRouteData = (data, map) => {
    if (!map) return;
    routeData = data;
    if (map.getSource('route')) {
        map.getSource('route').setData(routeData);
    }
};

/**
 *
 * @param {*} data
 * @param {*} map
 */
export const setPredfinedRouteData = (data, map) => {
    if (!map) return;
    predfinedRouteData = data;
    if (map.getSource('predefinedRouteLine')) {
        map.getSource('predefinedRouteLine').setData(predfinedRouteData);
    }
};

/**
 *
 */
export const getPredfinedRouteData = () => {
    return predfinedRouteData;
};

/**
 *
 * @param {*} data
 * @param {*} map
 */
export const setGeoJsonCircle = (data, map) => {
    if (!map) return;
    geoJsonCircle = data;
    map.getSource('circles').setData(geoJsonCircle);
};

/**
 *
 * @param {*} data
 * @param {*} map
 */
export const setGeoJsonPolygone = (data, map) => {
    if (!map) return;
    geoJsonPolygone = data;
    map.getSource('polygons').setData(geoJsonPolygone);
};

/**
 *
 * @param {*} data
 * @param {*} map
 */
export const setCircleGeoPoint = (data, map) => {
    circleGeoPoint = data;
    if (!map) return;
    map.getSource('geofencesCirlces').setData(circleGeoPoint);
};

/**
 *
 */
export const getGeoJsonCircle = () => {
    return geoJsonCircle;
};

/**
 *
 */
export const getGeoJsonPolygone = () => {
    return geoJsonPolygone;
};

/**
 *
 */
export const getCircleGeoPoint = () => {
    return circleGeoPoint;
};

/**
 * Promise wrappers
 * @param {*} cluster
 * @param {*} source
 * @param {*} map
 */
const getClusterLeaves = (cluster, source, map) =>
    new Promise((resolve, reject) => {
        if (!map) return;
        map.getSource(source).getClusterLeaves(
            cluster.properties.cluster_id,
            cluster.properties.point_count,
            0,
            (err, leaves) => {
                if (err) return reject(err);
                return resolve(leaves);
            }
        );
    });

/**
 *
 * @param {*} cluster
 * @param {*} source
 * @param {*} map
 */
const getExpansionZoom = (cluster, source, map) =>
    new Promise((resolve, reject) => {
        if (!map) return;
        map.getSource(source).getClusterExpansionZoom(cluster.id, (err, r) => {
            if (err) return reject(err);
            return resolve(r);
        });
    });

/**
 * Popup HTML functions
 * @param {*} param0
 */
const pingHtml = ([date, time] = [], vehicleName, auxIcons, spreaderInfo, loc, speed) => {
    const showAuxIcons =
        auxIcons == 'null' ? `` : `<img src="${auxIcons}" style="margin-right: 6px;"/> `;
    const list = getTranslationList(loc == undefined ? localStorage.getItem('locale') : loc);
    const mode =
        list[setSpreaderMode(spreaderInfo.mode)] == undefined
            ? 'NA'
            : list[setSpreaderMode(spreaderInfo.mode)];
    const state =
        list[setSpreaderState(spreaderInfo.state)] == undefined
            ? 'NA'
            : list[setSpreaderState(spreaderInfo.state)];
    const spreaderTitle = list['components.MapPopup.spreader.title'];
    const dateTitle = list['components.MapPopup.date'];
    const imgDimension = 'height="20px" width="20px"';
    let isEmpty = true;
    let material;

    for (const key of Object.keys(spreaderInfo)) {
        if (spreaderInfo[key] != null) {
            isEmpty = false;
            break;
        }
    }

    let displaySpreaderState;

    let displaySpreaderMode;

    let displaySpreaderSolid;

    let displaySpreaderSalt;

    let displaySpreaderDistance;

    let displayMixPercent;

    if (!isEmpty) {
        // get all spreader information and verify if it is undefined
        if (spreaderInfo.state != undefined) {
            displaySpreaderState = spreaderInfo.state != 255 ? `${state}` : 'NA';
        } else {
            displaySpreaderState = 'NA';
        }

        if (spreaderInfo.mode != undefined) {
            displaySpreaderMode = spreaderInfo.mode != 255 ? `${mode}` : 'NA';
        } else {
            displaySpreaderMode = 'NA';
        }

        if (spreaderInfo.solidMatterAmount) {
            displaySpreaderSolid =
                spreaderInfo.solidMatterAmount != 4294967295 && spreaderInfo.solidMatterAmount > 0
                    ? `${spreaderInfo.solidMatterAmount}`
                    : '--';
        } else {
            displaySpreaderSolid = '--';
        }

        if (spreaderInfo.saltWaterAmount) {
            displaySpreaderSalt =
                spreaderInfo.saltWaterAmount != 4294967295 && spreaderInfo.saltWaterAmount > 0
                    ? `${spreaderInfo.saltWaterAmount}`
                    : '--';
        } else {
            displaySpreaderSalt = '--';
        }

        if (spreaderInfo.traveledDistance) {
            displaySpreaderDistance =
                spreaderInfo.traveledDistance != 4294967295 && spreaderInfo.traveledDistance > 0
                    ? `${spreaderInfo.traveledDistance}`
                    : '--';
        } else {
            displaySpreaderDistance = '--';
        }

        if (spreaderInfo.additionalData) {
            const additionaData = JSON.parse(spreaderInfo.additionalData);

            if (additionaData.spreadedMaterialCode == 2) {
                material = additionaData.materialToBeSpread;
            } else if (
                additionaData.spreadedMaterialCode != 255 &&
                additionaData.spreadedMaterialCode > -1
            ) {
                material = list[getMaterial(additionaData.spreadedMaterialCode)];
            }

            if (spreaderInfo.saltWaterAmount != 4294967295 && displaySpreaderSalt != '--') {
                const getMixWater =
                    JSON.parse(spreaderInfo.additionalData).pourcentageMixWaterSalt != undefined
                        ? additionaData.pourcentageMixWaterSalt
                        : null;

                if (getMixWater) {
                    displayMixPercent = `(Mix ${getMixWater} %)`;
                } else {
                    displayMixPercent = '';
                }
            } else {
                displayMixPercent = '';
            }
        }
    }

    // display spreader only if it contains at least one spreader data information
    let displaySpreader;

    if (!isEmpty) {
        displaySpreader = `<div style="margin: 4px 8px; font-weight: 600; font-family: OpenSans; display:flex; flex-direction:column; border-top: 2px solid black;">
            <div style="margin: 2px 4px;">
                <img src=${spreaderIcon} style="margin-right: 3px;" height="40px" width="40px" />
                <strong>${spreaderTitle}</strong>
            </div>
            <div style=" margin: 0 8px; padding: 2px 8px;">

                <div style="flex: 1 0 50%; margin-bottom: 3px;"><img src=${stateIcon} ${imgDimension} style="margin-right: 6px;"/>
                    ${displaySpreaderState}
                </div>
                <div style="flex: 1 0 50%; margin-bottom: 3px;"><img src=${modeIcon}  ${imgDimension} style="margin-right: 6px;"/>
                    ${displaySpreaderMode}
                </div>
                <div style="flex: 1 0 50%; margin-bottom: 3px;"><img src=${blockIcon}  ${imgDimension} style="margin-right: 6px;"/>
                    ${displaySpreaderSolid} kg ${material ? `(${material})` : ''}
                </div>
                <div style="flex: 1 0 50%; margin-bottom: 3px;"><img src=${saltWaterIcon} ${imgDimension} style="margin-right: 6px;"/>
                    ${displaySpreaderSalt} kg ${displayMixPercent}
                </div>
                <div style="flex: 1 0 50%; margin-bottom: 3px;"><img src=${routeIcon} ${imgDimension} style="margin-right: 6px;"/>
                    ${displaySpreaderDistance} km
                </div>
            </div>
        </div>`;
    } else {
        displaySpreader = '';
    }

    // const displayVehicleName = `<div style="margin: 0px 8px; padding: 8px; display: flex; flex-direction: row; font-weight: 600; font-family: OpenSans;">
    //     <div style="display: flex; flex-direction: column;">
    //         <img src="${equipment}" style="margin-right: 8px;"/>
    //     </div>
    //     <strong>${vehicleName}</strong>
    // </div>`;
    // let period = time.split(':')[0] >= 12 ? ' PM' : ' AM';
    let timeToDisplay = `${time.split(':')[0]}:${time.split(':')[1]}`;
    const displayDate = `<div style="margin: 1px 4px; display:flex; flex-direction:row">
                <img src="${calendarIcon}"style="margin-right: 8px;"/>
                <div>${`${date}`}</div>
            </div>`;
    const displayTime = `<div style="margin: 5px 4px; display:flex; flex-direction:row">
            <img src="${clockIcon}"style="margin-right: 8px;"/>
            <div>${`${timeToDisplay}`}</div>
        </div>`;
    const displaySpeed = `<div style="margin: 0 4px; display:flex; margin-bottom: 3px;">
                <img src="${speedIcon}" style="margin-right: 10px;"/>
                <div>${speed}</div>
    </div>
    </div>`;
    const displayHTML = `<div style="display:flex; flex-direction: column; ">
            ${displayDate}
            ${displayTime}
            ${displaySpeed}
            ${displaySpreader}
        </div>`;
    return displayHTML;
};
const eventHistoryHtml = (properties) => {
    const date = new Date(properties.datetime);
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        seconds: '2-digit',
        hour12: false,
    };
    const formattedDate = date.toLocaleString('en-CA', options).replace(',', '');
    return `<div style="display:flex; flex-direction: column; ">
            <div style="margin: 1px 4px; display:flex; flex-direction:row">
                <img src="${calendarIcon}"style="margin-right: 8px;"/>
                <div>${formattedDate.split(' ')[0]}  &nbsp; </div>
            </div>
            <div style="margin: 5px 4px; display:flex; flex-direction:row">
            <img src="${clockIcon}"style="margin-right: 8px;"/>
            <div>${`${formattedDate.split(' ')[1]}`}</div>
        </div>
        <div style="margin: 0 4px; display:flex; margin-bottom: 3px;">
            <img src="${speedIcon}" style="margin-right: 10px;"/>
                <div>${properties.speed}</div>
            </div>
        </div>
        </div>`;
};
const getContrastYIQ = (hexcolor) => {
    hexcolor = hexcolor.replace('#', '');
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
};

/**
 * Popup HTML functions
 * @param {*} param0
 */
const PDRHtml = (item, intl) => {
    const pdrName = `<div style="padding: 8px; display: flex">
    <span style="color:${getContrastYIQ(item.color)}">${`${intl.formatMessage({
        id: 'components.pdr.Menu.name',
    })}: ${item.name}`}</span></div>`;
    const pdrPoids = JSON.parse(item.metadata).poids
        ? `<div style="padding: 8px; display: flex"><span style="color:${getContrastYIQ(
              item.color
          )}">${intl.formatMessage({ id: 'components.pdr.Menu.frequence' })}: ${
              JSON.parse(item.metadata).poids
          }</span></div>`
        : '';
    const displayHTML = `
    <div style="background:${item.color ||
        '#FFFFFF'} ; font-weight: 600; font-family: OpenSans; border: 2px solid black;
    border-radius: 10px;
    padding:5px 5px 5px 5px;
    text-align:center">
    ${pdrName}
    ${pdrPoids}
    ${
        item.dateInsertion != 'null'
            ? `<div style="padding: 8px; display: flex"><span style="color:${getContrastYIQ(
                  item.color
              )}">${intl.formatMessage({ id: 'components.pdr.Menu.dateInsertion' })}: ${moment(
                  item.dateInsertion
              ).format(dateFormat)}</span></div>`
            : ''
    }
    ${
        item.lastUpdated != 'null'
            ? `<div style="padding: 8px; display: flex"><span style="color:${getContrastYIQ(
                  item.color
              )}">${intl.formatMessage({ id: 'components.pdr.Menu.dateUpdate' })}: ${moment(
                  item.lastUpdated
              ).format(dateFormat)}</span></div>`
            : ''
    }
    </div>`;
    return displayHTML;
};

const PDRMatchingHtml = (item, intl) => {
    const pdrTimeStamp = `<div style="padding: 8px; display: flex">
    <span>${`${intl.formatMessage({ id: 'components.pdr.Menu.overlap.date' })}: ${moment(
        item.timestamp
    ).format(dateFormat)}`}</span></div>`;
    const displayHTML = `
    <div style="font-weight: 600; font-family: OpenSans; border: 2px solid black;
    border-radius: 10px;
    padding:5px 5px 5px 5px;
    text-align:center">
    ${item.timestamp ? pdrTimeStamp : ''}
    </div>`;
    return displayHTML;
};

const PDRHistoryHtml = (item, intl) => {
    const pdrVehicleName = `<div style="padding: 8px; display: flex">
    <span>${`${intl.formatMessage({ id: 'components.pdr.Menu.name' })}: ${
        item.vehicleName
    }`}</span></div>`;
    const displayHTML = `
    <div style="font-weight: 600; font-family: OpenSans; border: 2px solid black;
    border-radius: 10px;
    padding:5px 5px 5px 5px;
    text-align:center">
    ${item.vehicleName ? pdrVehicleName : ''}
    </div>`;
    return displayHTML;
};

/**
 *
 * @param {*} data
 */
const vehicleHtml = (data = {}) => {
    let route = '';
    if (data.properties.routeName !== '') {
        route = ` <br/ >
        Route: ${data.properties.routeName}`;
    }
    return `
    <div style="padding: 4px 6px; max-width: 180px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-weight: 600; font-family: OpenSans;">
        ${data.properties.name}  ${route}
    </div>
`;
};

/**
 *
 * @param {*} data
 */
const clusterHtml = (data = [], messageType, vehiclesNumberLeft, vehiclesLeftMessage) => {
    if (messageType === 2) {
        return `
              <div style="padding: 4px 6px; max-width: 180px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-weight: 600; font-family: OpenSans;">
                ${data.join('<br/>')}
                <br/>+ ${vehiclesNumberLeft} ${vehiclesLeftMessage}
              </div>
              `;
    } else if (messageType === 1) {
        return `
               <div style="padding: 4px 6px; max-width: 180px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-weight: 600; font-family: OpenSans;">
                ${data.join('<br/>')}
                <br/>+ ${vehiclesLeftMessage}
               </div>
               `;
    } else if (messageType === 0) {
        return `
               <div style="padding: 4px 6px; max-width: 180px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-weight: 600; font-family: OpenSans;">
                ${data.join('<br/>')}
               </div>
               `;
    }
};
/**
 * Gets a thunked map getter on it's closure for consistency
 * @param {*} getMap
 */
export const loadPinPDROnMap = (map, history, value) => {
    if (!map) return;
    const lng = [...history.getIn([value, 'points'])][1];
    const lat = [...history.getIn([value, 'points'])][0];
    marker.setLngLat([lng, lat]).addTo(map);
};

/**
 * Gets a thunked map getter on it's closure for consistency
 * @param {*} getMap
 */
export const onMapMouseMove = (mapRef, jwt, intl, selectedVehicleId) => (e) => {
    const map = mapRef.getMap();
    if (map && map._loaded) {
        let isHovering;
        let isPDRHovering;
        let isHistoryHovering;

        // Hover logic for Vehicles
        if (map.getLayer('vehicles-icons') && !isHovering) {
            const [first] = map.queryRenderedFeatures(e.point, {
                layers: ['vehicles-icons'],
            });
            if (first) {
                popup.setLngLat(first.geometry.coordinates);
                // Same for icons
                map.setLayoutProperty(
                    'vehicles-icons',
                    'icon-size',
                    idEquals(first.properties.sortKey, 0.55, 0.5) || null
                );
                if (!first.properties.showVehicleName && selectedVehicleId != first.id) {
                    isHovering = true;
                    popup.setHTML(vehicleHtml(first));
                }
            } else {
                // Sets the comparison to the id of -1, which no item has. Causes all items to de-large(?)
                map.setLayoutProperty('vehicles-icons', 'icon-size', idEquals(-1, 0.55, 0.5));
            }
        } else {
            // Sets the comparison to the id of -1, which no item has. Causes all items to de-large(?)
            map.setLayoutProperty('vehicles-icons', 'icon-size', idEquals(-1, 0.55, 0.5));
        }

        // Hover logic for Clusters
        if (map.getLayer('vehicles-clusters') && !isHovering) {
            const [first] = map.queryRenderedFeatures(e.point, {
                layers: ['vehicles-clusters'],
            });
            if (first) {
                isHovering = true;
                popup.setLngLat(first.geometry.coordinates);
                // if first element is a circle and not an icon it means it's hovering on a cluster
                if (first.layer.id === 'vehicles-clusters' && first.properties.cluster_id) {
                    map.setLayoutProperty(
                        'vehicles-clusters',
                        'icon-size',
                        idEquals(first.properties.cluster_id, 0.65, 0.6, true)
                    );
                    getClusterLeaves(first, 'vehicles', map).then((children) => {
                        let arr = [];
                        children.slice(0, 5).map((item) => arr.push(item.properties.name));
                        arr.sort();
                        //console.log(arr);
                        //console.log(children.length);
                        let vehiclesNumberLeft = children.length - 5;
                        return updatePopup(arr, vehiclesNumberLeft, intl);
                    });
                }
            } else {
                map.setLayoutProperty(
                    'vehicles-clusters',
                    'icon-size',
                    idEquals(-1, 0.65, 0.6, true)
                );
            }
        } else {
            map.setLayoutProperty('vehicles-clusters', 'icon-size', idEquals(-1, 0.65, 0.6, true));
        }

        // Hover logic for events markers
        if (map.getLayer('events') && !isHovering) {
            const [circle] = map.queryRenderedFeatures(e.point, {
                // Only looks on events and icons layer for mouse-move matches
                layers: ['events'],
            });
            if (circle) {
                popup.setLngLat(circle.geometry.coordinates);
                popup.setHTML(
                    pingHtml(
                        circle.properties.datetime.split(' '),
                        circle.properties.VehName,
                        circle.properties.auxIcon,
                        JSON.parse(circle.properties.spreaderData),
                        circle.properties.locale,
                        circle.properties.speed
                    )
                );
                // Makes it so the events circle have conditional size on hover, that way the hovered one gets enlarged
                // Event and icon share the same ID so you only need to check for a hover on events
                map.setLayoutProperty(
                    'events',
                    'icon-size',
                    idEquals(circle.properties.sortKey, 0.55, 0.5)
                );
                isHovering = true;
            }
        } else {
            // Sets the comparison to the id of -1, which no item has. Causes all items to go back to initial size
            map.setLayoutProperty('events', 'icon-size', idEquals(-1, 0.55, 0.5));
        }

        // Hover logic for pings' layer
        if (map.getLayer('pings') && !isHovering) {
            const [top] = map.queryRenderedFeatures(e.point, {
                layers: ['pings'],
            });

            if (top) {
                popup.setLngLat(top.geometry.coordinates);
                popup.setHTML(
                    pingHtml(
                        top.properties.datetime.split(' '),
                        top.properties.VehName,
                        top.properties.auxIcon,
                        JSON.parse(top.properties.spreaderData)
                    ),
                    top.properties.locale
                );
                isHovering = true;
            }
        }
        //Hover logic for events history
        if (map.getLayer('events-layer') && !isHovering) {
            const [top] = map.queryRenderedFeatures(e.point, {
                layers: ['events-layer'],
            });

            if (top) {
                popup.setLngLat(top.geometry.coordinates);
                popup.setHTML(eventHistoryHtml(top.properties));
                isHovering = true;
            }
        }
        // Hover logic PDR for route History layer
        /*if (HistoryPDRhoveredStateId != null) {
            map.setFeatureState(
                { source: 'predefinedroutehistory', id: HistoryPDRhoveredStateId },
                { hover: false }
            );
            HistoryPDRhoveredStateId = null;
        }
        if (map.getLayer('predefinedroutehistory') && !isHovering) {
            const [top] = map.queryRenderedFeatures(e.point, {
                layers: ['predefinedroutehistory'],
            });

            if (top) {
                HistoryPDRhoveredStateId = top.id;
                map.setFeatureState(
                    { source: top.layer.id, id: HistoryPDRhoveredStateId },
                    { hover: true }
                );
                popup.setLngLat(e.lngLat);
                popup.setHTML(PDRHistoryHtml(top.properties, intl));
                isHovering = true;
            }
        }

        // Hover logic for route History layer
        if (
            jwt &&
            jwt.permissions &&
            Array.isArray(jwt.permissions) &&
            jwt.permissions.indexOf('read:vehicle-settings') != -1 &&
            jwt.permissions.indexOf('access:predefined-routes') != -1 &&
            HistoryhoveredStateId != null
        ) {
            map.setFeatureState({ source: 'route', id: HistoryhoveredStateId }, { hover: false });
            map.setFeatureState({ source: 'line', id: HistoryhoveredStateId }, { hover: false });
            HistoryhoveredStateId = null;
        }
        if (
            jwt &&
            jwt.permissions &&
            Array.isArray(jwt.permissions) &&
            jwt.permissions.indexOf('read:vehicle-settings') != -1 &&
            jwt.permissions.indexOf('access:predefined-routes') != -1 &&
            map.getLayer('route') &&
            map.getLayer('line') &&
            !isHistoryHovering
        ) {
            const [top] = map.queryRenderedFeatures(e.point, {
                layers: ['route', 'line'],
            });

            if (top) {
                HistoryhoveredStateId = top.id;
                map.setFeatureState(
                    { source: top.layer.id, id: HistoryhoveredStateId },
                    { hover: true }
                );
                isHistoryHovering = true;
            }
        }

        // Hover logic for Matching LineString
        if (PDRMAtchinghoveredStateId != null) {
            map.setFeatureState(
                { source: 'predefinedroutematching', id: PDRMAtchinghoveredStateId },
                { hover: false }
            );
            PDRMAtchinghoveredStateId = null;
        }
        if (map.getLayer('predefinedroutematching') && !isHovering) {
            const [top] = map.queryRenderedFeatures(e.point, {
                layers: ['predefinedroutematching'],
            });
            if (top) {
                PDRMAtchinghoveredStateId = top.id;
                map.setFeatureState(
                    { source: 'predefinedroutematching', id: PDRMAtchinghoveredStateId },
                    { hover: true }
                );
                popup.setLngLat(e.lngLat);
                popup.setHTML(PDRMatchingHtml(top.properties, intl));
                isHovering = true;
            }
        }

        // Hover logic for predefined routes LineString
        if (PDRhoveredStateId != null) {
            map.setFeatureState(
                { source: 'predefinedRouteLine', id: PDRhoveredStateId },
                { hover: false }
            );
            map.setFeatureState(
                { source: 'predefinedrouteResults', id: PDRhoveredStateId },
                { hover: false }
            );
            PDRhoveredStateId = null;
        }
        if (
            map.getLayer('predefinedRouteLine') &&
            map.getLayer('predefinedrouteResults') &&
            !isHovering
        ) {
            const [top] = map.queryRenderedFeatures(e.point, {
                layers: ['predefinedRouteLine', 'predefinedrouteResults'],
            });
            if (top) {
                PDRhoveredStateId = top.id;
                map.setFeatureState(
                    { source: 'predefinedRouteLine', id: PDRhoveredStateId },
                    { hover: true }
                );
                map.setFeatureState(
                    { source: 'predefinedrouteResults', id: PDRhoveredStateId },
                    { hover: true }
                );
                popup.setLngLat(e.lngLat);
                popup.setHTML(PDRHtml(top.properties, intl));
                isHovering = true;
            }
        }

        */
        // Changes cursor to pointer if hovering any item and adds popup
        if (isHovering) {
            e.target.style.cursor = 'pointer';
            popup.addTo(map);
        } else {
            e.target.style.cursor = 'grab';
            if(popup){
                popup.remove();
            }
        }
    }
};
function updatePopup(arr, vehiclesNumberLeft, intl) {
    let vehiclesLeftMessage;
    if (vehiclesNumberLeft <= 0) {
        return popup.setHTML(clusterHtml(arr, 0));
    } else if (vehiclesNumberLeft == 1) {
        vehiclesLeftMessage = intl.formatMessage({
            id: 'views.MapPage.Cluster.other.one',
        });
        return popup.setHTML(clusterHtml(arr, 1, vehiclesNumberLeft, vehiclesLeftMessage));
    } else {
        vehiclesLeftMessage = intl.formatMessage({
            id: 'views.MapPage.Cluster.other.multiple',
        });
        return popup.setHTML(clusterHtml(arr, 2, vehiclesNumberLeft, vehiclesLeftMessage));
    }
}
/**
 *
 */
const highlightColor = '#0000ff';

/**
 *
 * @param {*} map
 */
export const clearHighlighted = (map) => {
    if (!map) return;
    map.setLayoutProperty('vehicles-icons-highlight', 'icon-image', [
        'get',
        idEquals(-1, 'highlight', 'icon'),
    ]);
    // map.setLayoutProperty('events', 'icon-image', ['get', idEquals(-1, 'highlight', 'icon')]);
    // map.setPaintProperty('pings-shadow', 'circle-color', idEquals(-1, highlightColor, '#000'));
    // map.setPaintProperty('pings-shadow', 'circle-radius', idEquals(-1, 6 * 1.7, 6 * 1.4));
    map.setLayoutProperty('vehicles-icons-highlight', 'icon-size', 0.5);
    map.setLayoutProperty('vehicles-icons-highlight', 'icon-size', idEquals(-1, 0.6, 0));
};

/**
 *
 * @param {*} vehicleId
 * @param {*} map
 */
export const highlightVehicle = (vehicleId, map) => {
    if (!map) return;
    map.setLayoutProperty('vehicles-icons-highlight', 'icon-image', [
        'get',
        idEquals(vehicleId, 'highlight', 'icon'),
    ]);
    map.setLayoutProperty('vehicles-icons-highlight', 'icon-size', [
        'case',
        ['==', ['get', 'sortKey'], vehicleId],
        0.6,
        0.5,
    ]);
};

/**
 * onClick logic
 * @param {*} getMap
 * @param {*} vehicles
 * @param {*} vehicleCb
 * @param {*} clusterCb
 * @param {*} historyCb
 */
export const onMapMouseDown = (
    getMap,
    vehicles,
    vehicleCb,
    clusterCb,
    historyCb,
    historyLineCb,
    jwt
) => async (e) => {
    const map = getMap();
    if (!map) return;

    let foundPOI = false;
    // Vehicle/Cluster mousedown logic
    if (map.getLayer('vehicles-icons') && map.getLayer('vehicles-clusters')) {
        const [top] = map.queryRenderedFeatures(e.point, {
            layers: ['vehicles-clusters', 'vehicles-icons'],
        });

        if (top) {
            foundPOI = true;
            // `Top` will be a Cluster point if it has these properties
            if (top.properties.cluster && top.properties.point_count) {
                // Obtain cluster's children and zoom level to spread
                Promise.all([
                    getClusterLeaves(top, 'vehicles', map),
                    getExpansionZoom(top, 'vehicles', map),
                ])
                    .then(([children, zoom]) => {
                        // Increases zoom by 1 for better spread
                        top.properties.zoom_level = zoom + 1;
                        if (clusterCb) {
                            clusterCb(top, children.map((item) => item.id), vehicles);
                        }
                    })
                    .catch((e) => {
                        console.log('ERROR');
                        console.log(e);
                    });

                clearHighlighted(map);

                map.setLayoutProperty(
                    'vehicles-clusters',
                    'icon-image',
                    makeClusterSwitch(100, map, [
                        top.properties.cluster_id,
                        top.properties.point_count,
                    ])
                );
                // Vehicle marker click handler
            } else {
                clearHighlighted(map);
                if (vehicleCb) {
                    vehicleCb(
                        {
                            id: top.id,
                            source: top.properties.source,
                        },
                        false
                    );
                }
            }
        }
    }

    // Events and pings mouse handler
    if (map.getLayer('events')) {
        const [top] = map.queryRenderedFeatures(e.point, {
            layers: ['events', 'auxEvents'],
        });
        if (top) {
            foundPOI = true;
            // const isPing = top.layer.id === 'pings';
            clearHighlighted(map);
            if (historyCb) {
                historyCb(
                    top.properties.vehicleId,
                    top.properties.datetime,
                    top.properties.type,
                    top.properties.realtimeId
                );
            }
        }
    }

    // Mouse handler line History layer
    if (
        !foundPOI && // Don't process line events if there was a more interesting point
        jwt &&
        jwt.permissions &&
        Array.isArray(jwt.permissions) &&
        jwt.permissions.indexOf('read:vehicle-settings') != -1 &&
        jwt.permissions.indexOf('access:predefined-routes') != -1 &&
        map.getLayer('line')
    ) {
        const [top] = map.queryRenderedFeatures(e.point, {
            layers: ['line'],
        });

        if (top) {
            const coordsArray =
                (top.properties.coordinates && JSON.parse(top.properties.coordinates)) || [];
            if (historyLineCb && coordsArray.length > 1) {
                (top.properties.name = (await mapboxGeoToAddress(
                    coordsArray[0][1],
                    coordsArray[0][0]
                ).then((locations) => {
                    let address = null;
                    if (locations && locations.data && locations.data.features) {
                        address = `${(locations.data.features[0] &&
                            locations.data.features[0].address) ||
                            ''} ${(locations.data.features[0] && locations.data.features[0].text) ||
                            ''}`;
                    }
                    return address && address.length > 1 ? (
                        address
                    ) : (
                        <FormattedMessage id={'components.pdr.Untitled'} />
                    );
                })) || <FormattedMessage id={'components.pdr.Untitled'} />),
                    (top.properties.coords = coordsArray);
                historyLineCb(top.properties.name, top.properties.color, 1, coordsArray);
            }
        }
    }

    // Mouse handler route History layer
    if (
        !foundPOI && // Don't process line events if there was a more interesting point
        jwt &&
        jwt.permissions &&
        Array.isArray(jwt.permissions) &&
        jwt.permissions.indexOf('read:vehicle-settings') != -1 &&
        jwt.permissions.indexOf('access:predefined-routes') != -1 &&
        map.getLayer('route')
    ) {
        const [top] = map.queryRenderedFeatures(e.point, {
            layers: ['route'],
        });

        if (top) {
            const coordsArray =
                (top.properties.coordinates && JSON.parse(top.properties.coordinates)) || [];
            if (historyLineCb && coordsArray.length > 1) {
                (top.properties.name = (await mapboxGeoToAddress(
                    coordsArray[0][1],
                    coordsArray[0][0]
                ).then((locations) => {
                    let address = null;
                    if (locations && locations.data && locations.data.features) {
                        address = `${(locations.data.features[0] &&
                            locations.data.features[0].address) ||
                            ''} ${(locations.data.features[0] && locations.data.features[0].text) ||
                            ''}`;
                    }
                    return address && address.length > 1 ? (
                        address
                    ) : (
                        <FormattedMessage id={'components.pdr.Untitled'} />
                    );
                })) || <FormattedMessage id={'components.pdr.Untitled'} />),
                    (top.properties.coords = coordsArray);
                historyLineCb(top.properties.name, top.properties.color, 1, coordsArray);
            }
        }
    }
};

/**
 * onHover logic
 * @param {*} getMap
 */
export const onMapHover = (mapRef) => (e) => {
    const map = mapRef.getMap();
    if (!map) return;
    const feature =
        e &&
        e.features &&
        e.features.find(
            (f) => f.layer.id === 'geofencesPolygons' || f.layer.id === 'geofencesCirlces'
        );
    if (feature && e.features[0].properties.name != undefined) {
        popup.setLngLat(e.lngLat);
        popup.setHTML(e.features[0].properties.name);
        popup.addTo(map);
    }
};

/**
 * Toggles between displaying history layers and vehicles layers
 * @param {*} isHistory
 * @param {*} map
 */
export const setIsHistoryMode = (isHistory, map, from = '') => {
    if (!map) return;
    // If more layers are added in the future, add them below to properly swap states
    // History mode layers
    const historyVisibility = isHistory ? 'visible' : 'none';
    const historyLayers = ['events', 'line', 'arrows', 'pings'];
    // Non history mode layers
    const vehicleVisibility = !isHistory ? 'visible' : 'none';
    const vehicleLayers = [
        'vehicles-icons',
        'vehicles-icons-arrow',
        'vehicles-icons-text',
        'vehicles-clusters',
        'vehicles-clusters-icon',
        'routific-shadow',
        'vehicles-icons-highlight',
    ];
    historyLayers.forEach((layer) => {
        if (map.getLayer(layer)) map.setLayoutProperty(layer, 'visibility', historyVisibility);
    });
    vehicleLayers.forEach((layer) => {
        if (map.getLayer(layer)) map.setLayoutProperty(layer, 'visibility', vehicleVisibility);
    });

    if (!isHistory) {
        // reset visiblity to none when exit history mode
        // this option is toggle manualy by the user
        if (map.getLayer('route')) map.setLayoutProperty('route', 'visibility', 'none');
    }
};

/**
 * Toggles between displaying PDR layers and vehicles layers
 * @param {*} isPDR
 * @param {*} map
 */
export const setIsPDRMode = (isPDR, map, from = '') => {
    if (!map) return;
    const predfinedRouteVisibility = !isPDR ? 'visible' : 'none';
    const predfinedRouteLayers = ['predefinedRouteLine', 'predefinedRouteLinearrows'];
    const vehicleVisibility = !isPDR ? 'visible' : 'none';
    const vehicleLayers = [
        'vehicles-icons',
        'vehicles-icons-arrow',
        'vehicles-icons-text',
        'vehicles-clusters',
        'vehicles-clusters-icon',
        'routific-shadow',
        'vehicles-icons-highlight',
    ];
    predfinedRouteLayers.forEach((layer) => {
        if (map.getLayer(layer))
            map.setLayoutProperty(layer, 'visibility', predfinedRouteVisibility);
    });
    vehicleLayers.forEach((layer) => {
        if (map.getLayer(layer)) map.setLayoutProperty(layer, 'visibility', vehicleVisibility);
    });
};

/**
 *
 * @param {*} map
 */
export const resetLayers = (map) => {
    if (!map) return;
    if (map.getSource('line')) {
        map.getSource('line').setData({
            type: 'FeatureCollection',
            features: [],
        });
    }
    if (map.getSource('route')) {
        map.getSource('route').setData({
            type: 'FeatureCollection',
            features: [],
        });
    }
    if (map.getSource('predefinedroutehistory')) {
        map.getSource('predefinedroutehistory').setData({
            type: 'FeatureCollection',
            features: [],
        });
    }
    if (map.getSource('predefinedroutematching')) {
        map.getSource('predefinedroutematching').setData({
            type: 'FeatureCollection',
            features: [],
        });
    }
    if (map.getSource('predefinedrouteResults')) {
        map.getSource('predefinedrouteResults').setData({
            type: 'FeatureCollection',
            features: [],
        });
    }
    if (map.getSource('pings')) {
        map.getSource('pings').setData({
            type: 'FeatureCollection',
            features: [],
        });
    }
    if (map.getSource('events')) {
        map.getSource('events').setData({
            type: 'FeatureCollection',
            features: [],
        });
    }
    pingData.features = [];
    eventData.features = [];
    auxData.features = [];
    lineData.features = [];
    routeData.features = [];
    routePDRHistoryData.features = [];
    routePDRmatchingData.features = [];
    routePDRData.features = [];
    geoJsonCircle.features = [];
    circleGeoPoint.features = [];
    geoJsonPolygone.features = [];
    setIsHistoryMode(false, map);
    setIsPDRMode(false, map);
    removeMarkers(map);
};

/**
 * Removed coordinates that are followed by an equal one, such as from a double ping on the same coordinate
 * @param {*} waypoints
 */
export const filterRepeated = (waypoints) => {
    const filteredWaypoints = [];
    waypoints.forEach((curr, i) => {
        const next = waypoints[Number(i) + 1];
        if (curr && next && curr[0] === next[0] && curr[1] === next[1]) return;
        filteredWaypoints.push(curr);
    });
    return filteredWaypoints;
};
export const hasStatus = (status) => {
    const statuses = [
        'KEY_ON',
        'DRIVER_IDENT',
        'ENGINE_ON',
        'ENGINE_OFF',
        'ECU',
        'STATE',
        'BUTTON',
        'PING',
    ];
    return status ? statuses.includes(status.eventType) : false;
};
/**
 * Maps vehicle reducer data into a source
 * @param {*} map
 * @param {*} vehicles
 * @param {*} eventTypes
 * @param {*} routificsRecords
 */

// Utilisation

export const mapVehicles = async (
    map,
    vehicles,
    eventTypes,
    routificsRecords,
    clearShadow,
    loc,
    vehiclesAnimate,
    isShowVehicleNames,
    selectedVehicle,
    historyClickedData
) => {
    const data = {
        type: 'FeatureCollection',
        features: [],
    };
    if (!map || !map.getSource('vehicles')) return;
    data.features = vehicles
        .map((vehicle) => {
            const getEventId = vehicle.getIn(['status', 'eventTypeId']);
            const AuxStatus = new Array(4).fill(0);
            if (vehicle.getIn(['latestRealTime', 'sensorAux'])) {
                AuxStatus[0] = 1;
            }
            if (vehicle.getIn(['latestRealTime', 'sensorAux2'])) {
                AuxStatus[1] = 1;
            }
            if (vehicle.getIn(['latestRealTime', 'sensorAux3'])) {
                AuxStatus[2] = 1;
            }
            if (vehicle.getIn(['latestRealTime', 'sensorAux4'])) {
                AuxStatus[3] = 1;
            }

            const auxStatusIcon = getAuxStatus(AuxStatus);
            const isEmptyAux = AuxStatus.every((item) => item === 0);

            const isKey = vehicle.getIn(['latestRealTime', 'sensorKey']);

            const auxState = {
                aux_1: vehicle.getIn(['latestRealTime', 'sensorAux']),
                aux_2: vehicle.getIn(['latestRealTime', 'sensorAux2']),
                aux_3: vehicle.getIn(['latestRealTime', 'sensorAux3']),
                aux_4: vehicle.getIn(['latestRealTime', 'sensorAux4']),
            };
            const additionalData = vehicle.get('additionalData');

            const status = customMapIconsHelper(
                vehicle,
                eventTypes,
                isKey,
                additionalData,
                auxState
            );

            let circleRadius = 0;
            let shadowColor = '#000';
            let circleBlur = 0.8;
            let routeName = '';
            if (routificsRecords) {
                routificsRecords.forEach((route, idd) => {
                    const linkedRoutes = route.get('linkedRoutes');
                    const rt = linkedRoutes.filter((e) => {
                        return e.get('vehicleId') === vehicle.get('id');
                    });
                    if (rt.size > 0) {
                        rt.forEach((r, ii) => {
                            if (r.get('color') !== '') {
                                shadowColor = clearShadow ? '#000' : r.get('color');
                                circleRadius = clearShadow ? 0 : 35;
                                circleBlur = clearShadow ? 0 : 0.5;
                                routeName = r.get('routeID');
                            }
                        });
                    }
                });
            }
            let coordinates = [
                vehicle.getIn(['latestRealTime', 'longitudeWgs84']),
                vehicle.getIn(['latestRealTime', 'latitudeWgs84']),
            ];
            if (vehiclesAnimate) {
                // If we have animation data,  let's not disturb that
                const vehicleAnimate = vehiclesAnimate.get(vehicle.get('id'));
                if (
                    vehicleAnimate &&
                    vehicleAnimate.latestRealTime &&
                    vehicleAnimate.latestRealTime.longitudeWgs84
                ) {
                    coordinates = [
                        vehicleAnimate.latestRealTime.longitudeWgs84,
                        vehicleAnimate.latestRealTime.latitudeWgs84,
                    ];
                }
            }

            if (
                additionalData &&
                additionalData.toJS().icons &&
                hasStatus(status) &&
                !map.hasImage(vehicle.get('id').toString())
            ) {
                initLayers(map, true);
            }

            if (vehicle.get('id') === selectedVehicle.getIn([0, 'id'])) {
                selectedVehicle.set(0, vehicle); // refresh selectedVehicle data
            }

            let showDirection = true;
            if (vehicle.getIn(['latestRealTime', 'eventType', 'description']) === 'KEY_OFF') {
                showDirection = false;
            }

            return {
                id: vehicle.get('id'),
                type: 'Feature',
                properties: {
                    sortKey: vehicle.get('id'),
                    color: (status && status.color) || '#000',
                    shadowColor,
                    circleRadius,
                    circleBlur,
                    routeName,
                    icon:
                        additionalData && additionalData.toJS().icons && hasStatus(status)
                            ? vehicle.get('id').toString()
                            : status && status.icon,
                    iconCustom: additionalData ? additionalData : null,
                    heading: vehicle.getIn(['latestRealTime', 'heading']),
                    isKey: showDirection,
                    highlight: status && status.highlight,
                    name: vehicle.get('name'),
                    datetime: vehicle.getIn(['latestRealTime', 'datetime']),
                    source: vehicle.get('source'),
                    auxIcon: isEmptyAux ? null : auxStatusIcon.icon,
                    locale: loc,
                    status: vehicle.get('status'),
                    showVehicleName: isShowVehicleNames,
                },
                geometry: {
                    type: 'Point',
                    coordinates,
                },
            };
        })
        .toJS();
    vehicleData = data;
    map.getSource('vehicles').setData(vehicleData);

    // ECP-I18: update marker position on vehicle data refresh
    if (
        isMarkerInitialize &&
        selectedVehicle &&
        selectedVehicle.get(0) &&
        selectedVehicle.getIn([0, 'latestRealTime'])
    ) {
        const coords = [
            selectedVehicle.getIn([0, 'latestRealTime', 'longitudeWgs84']),
            selectedVehicle.getIn([0, 'latestRealTime', 'latitudeWgs84']),
        ];

        const fullDate = format(selectedVehicle.getIn([0, 'since']), 'YYYY/MM/DD,HH mm');
        const info = {
            name: selectedVehicle.getIn([0, 'name']),
            date: fullDate.split(',')[0],
            time: fullDate.split(',')[1].replace(' ', ' h '),
        };
        setMarkers(map, info, coords, historyClickedData);
    }
};

export const auxEventName = (aux, state) => {
    if (aux == undefined) {
        return undefined;
    }
    if (state) {
        return `${aux}_` + `ON`;
    } else {
        return `${aux}_` + `OFF`;
    }
};

function auxNumber(eventTypeId, separator) {
    if (separator === undefined) {
        separator = '';
    }
    const prefix = `aux${separator}`;
    if (eventTypeId === 9) {
        return `${prefix}1`;
    } else if (eventTypeId === 17) {
        return `${prefix}2`;
    } else if (eventTypeId === 18) {
        return `${prefix}3`;
    } else if (eventTypeId === 19) {
        return `${prefix}4`;
    } else return undefined;
}

/**
 * Maps ping data into a source
 * @param {*} pings
 * @param {*} eventTypes
 * @param {*} vehicleId
 * @param {*} map
 */
export const mapPingData = (pings, eventTypes, vehicleId, map, VehName, loc, speed) => {
    pings.forEach((coord, index) => {
        const originalCoordinate = coord.get('originalCoordinate'); // Use real positions for markers
        const longitude = originalCoordinate.get(0);
        const latitude = originalCoordinate.get(1);
        const eventTypeId = coord.get('eventTypeId');
        const isKey = coord
            .getIn(['vehicle', 'sensors'])
            .get(0)
            .get('value');
        const spreaderInfo = coord.getIn(['vehicle', 'spreaderData']);
        const additionalData = coord.getIn(['vehicle', 'additionalData'])
            ? coord.getIn(['vehicle', 'additionalData']).toJS()
            : '';

        // verify if it contains an active aux
        const AuxStatus = new Array(4).fill(0);
        if (coord.getIn(['vehicle', 'sensorAux'])) {
            AuxStatus[0] = 1;
        }
        if (coord.getIn(['vehicle', 'sensorAux2'])) {
            AuxStatus[1] = 1;
        }
        if (coord.getIn(['vehicle', 'sensorAux3'])) {
            AuxStatus[2] = 1;
        }
        if (coord.getIn(['vehicle', 'sensorAux4'])) {
            AuxStatus[3] = 1;
        }
        const auxStatusIcon = getAuxStatus(AuxStatus);
        const isEmptyAux = AuxStatus.every((item) => item === 0);

        // verify if the eventType is a ping
        let isPing = false;
        if (eventTypeId == 7) {
            isPing = true;
        }

        const auxSensors = {
            aux1: coord.getIn(['vehicle', 'sensorAux']),
            aux2: coord.getIn(['vehicle', 'sensorAux2']),
            aux3: coord.getIn(['vehicle', 'sensorAux3']),
            aux4: coord.getIn(['vehicle', 'sensorAux4']),
        };

        // Do not display engine ON/OFF in history trail
        if (eventTypeId != 47 && eventTypeId != 46) {
            const status = getCustomHistoryIcon(
                eventTypes,
                isPing ? null : isKey,
                additionalData,
                auxSensors,
                eventTypeId
            );

            const pushData = {
                id: coord.getIn(['vehicle', 'realtimeId']),
                type: 'Feature',
                properties: {
                    VehName,
                    vehicleId,
                    realtimeId: coord.getIn(['vehicle', 'realtimeId']),
                    datetime: coord.get('datetime'),
                    color:
                        (status && status.color && status.color.primary) ||
                        (status && status.color) ||
                        '#000',
                    dotColor:
                        (status && status.color && status.color.primary) ||
                        (status && status.color) ||
                        '#000',
                    type: status && status.eventType,
                    eventTypeId,
                    eventTypeName: auxEventName(
                        auxNumber(eventTypeId),
                        auxSensors[auxNumber(eventTypeId)]
                    ),
                    auxSensors,
                    icon: status && status.icon,
                    highlight: status && status.highlight,
                    sortKey: coord.getIn(['vehicle', 'realtimeId']),
                    auxIcon: isEmptyAux ? null : auxStatusIcon.icon,
                    spreaderData: spreaderInfo,
                    locale: loc,
                    speed: speed[index],
                },
                geometry: {
                    type: 'Point',
                    coordinates: [longitude, latitude],
                },
            };

            if (eventTypeId === 7 || eventTypeId === 33) {
                pingData.features.push(pushData);
            } else {
                eventData.features.push(pushData);
            }
        }
    });

    if (map.getSource('pings')) {
        map.getSource('pings').setData(pingData);
    }

    if (map.getSource('events')) {
        map.getSource('events').setData(eventData);
    }
};

{
    /* <img style="margin-right:2px" ${
    isCustomIcon ? `width="26" height="30"` : `width="14" height="20"`
} src="${status.icon}" />  */
}

/**
 * add marker and permanentPopup
 * @param {*} map
 * @param {*} info
 * @param {*} coords
 */
let activeMarker = null;

export const setMarkers = (map, info, coords, historyClickedData) => {
    if (!map || !historyClickedData) {
        return;
    }

    const eventType = historyClickedData.eventType || '';
    const driverName = historyClickedData.driverName || '';
    const speed = historyClickedData.Speed || '';
    const { name, date, time } = info;

    const text = `
    <div style="text-align:center; margin: 2%; padding: 4px 6px; max-width: 180px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
    font-weight: 600; font-family: OpenSans;">
        <div style="margin: 2px 0">${name}</div>
        <div style="text-align:center; border-top: 1px solid black;">${date}</div>
        <div style="text-align:center;">${time}</div>
        ${
            eventType === 'Overspeed'
                ? `
        <div>
          <div style="text-align:center; color:grey;">${driverName}</div>
          <div style="text-align:center; color:grey;">${speed}</div>
        </div>`
                : ''
        }
    </div>`;

    if (activeMarker) {
        activeMarker.remove();
    }

    const iconElement = document.createElement('div');
    iconElement.innerHTML = `<img src="${mapPin}" style="width: 40px; height: 40px; position: relative; bottom: 20px; left: 7px;" alt="Marker Icon">`;

    activeMarker = new Marker({ element: iconElement }).setLngLat(coords);
    activeMarker.addTo(map);
    if (historyClickedData && Object.keys(historyClickedData).length > 0) {
        permanentPopup
            .setHTML(text)
            .setLngLat(coords)
            .addTo(map);
    }
    isMarkerInitialize = true;
};

/**
 * Remove circles
 * @param {*} map
 */
export const removeCircles = (map) => {
    eventData.features.map((el) => {
        el.properties = { visibility: 'none' };
    });
    if (map.getSource('events')) {
        map.getSource('events').setData(eventData);
    }
};

/**
 * Remove marker and permanentPopup
 * @param {*} map
 */
export const removeMarkers = (map) => {
    if (!map) return;

    if(marker){
        marker.remove();
    }
    if(permanentPopup){
        permanentPopup.remove();
    }

    isMarkerInitialize = false;
};
/**
 * set start and end of history marker
 * @param {*} map
 * @param {*} coords
 * @param {*} info
 */
export const setHistoryMarker = (map, coords, info) => {
    if(marker){
        marker.remove();
    }
    if(permanentPopup){
        permanentPopup.remove();
    }

    isMarkerInitialize = false;
    if(activeMarker){
        activeMarker.remove();
    }

    // set the coordinates
    historyStartMarker.setLngLat(coords[0]).addTo(map);
    historyEndMarker.setLngLat(coords[1]).addTo(map);
};

/**
 * remove start and end of history marker
 */
export const removeHistoryMarker = () => {
    if(historyStartMarker){
        historyStartMarker.remove();
    }
    if(historyEndMarker){
        historyEndMarker.remove();
    }

};

export const customMapIconsHelper = (vehicle, eventTypes, isKey, additionalData, auxState) => {
    let status = '';
    if (additionalData != null && Object.keys(additionalData).includes('icons')) {
        const vehicleIcons = additionalData.icons.vehicle;
        const auxIcons = additionalData.icons.aux;
        if (
            auxState &&
            auxIcons &&
            (auxState.aux_1 || auxState.aux_2 || auxState.aux_3 || auxState.aux_4)
        ) {
            if (auxIcons.aux_1.id != 0 && auxState.aux_1) {
                status = getMapCustomIcons(auxIcons.aux_1.id, auxIcons.aux_1.color);
            } else if (auxIcons.aux_2.id != 0 && auxState.aux_2) {
                status = getMapCustomIcons(auxIcons.aux_2.id, auxIcons.aux_2.color);
            } else if (auxIcons.aux_3.id != 0 && auxState.aux_3) {
                status = getMapCustomIcons(auxIcons.aux_3.id, auxIcons.aux_3.color);
            } else if (auxIcons.aux_4.id != 0 && auxState.aux_4) {
                status = getMapCustomIcons(auxIcons.aux_4.id, auxIcons.aux_4.color);
            } else {
                status = getMapVehicleStatus(
                    vehicle,
                    vehicle.getIn(['status', 'eventTypeId']),
                    eventTypes,
                    false,
                    isKey
                );
            }
        } else {
            // if (vehicleIcons && vehicleIcons.id != 0) {
            //     status = getMapCustomIcons(vehicleIcons.id, vehicleIcons.color);
            // } else {
            status = getMapVehicleStatus(
                vehicle,
                vehicle.getIn(['status', 'eventTypeId']),
                eventTypes,
                false,
                isKey
            );
        }
    } else {
        status = getMapVehicleStatus(
            vehicle,
            vehicle.getIn(['status', 'eventTypeId']),
            eventTypes,
            false,
            isKey
        );
    }

    return status;
};

const getCustomHistoryIcon = (eventTypes, isPing, additionalData, auxSensors, eventTypeId) => {
    let status;
    const icons = additionalData.icons;
    const isDefault = false;

    const aux = auxNumber(eventTypeId, '_');
    if (icons != null && icons && aux !== undefined) {
        if (
            Object.keys(icons).includes('aux') &&
            additionalData.icons.aux &&
            additionalData.icons.aux[aux].id > 0
        ) {
            status = getMapCustomIcons(
                additionalData.icons.aux[aux].id,
                additionalData.icons.aux[aux].color
            );
        }
    } else {
        status = getMapVehicleStatus(null, eventTypeId, eventTypes, false, isPing, auxSensors);
    }
    return status;
};
